import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "read" }
const _hoisted_2 = { class: "page-header big" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  key: 1,
  class: "title"
}
const _hoisted_5 = { class: "read-page" }
const _hoisted_6 = {
  key: 0,
  class: "read-page-content"
}
const _hoisted_7 = { class: "image" }
const _hoisted_8 = { class: "metadata" }
const _hoisted_9 = { class: "actions" }
const _hoisted_10 = {
  key: 1,
  class: "read-page-content"
}
const _hoisted_11 = { class: "image" }
const _hoisted_12 = { class: "preview" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "buttons" }
const _hoisted_15 = { class: "temporal-serie" }
const _hoisted_16 = { class: "temporal-serie action-card" }
const _hoisted_17 = { class: "header" }
const _hoisted_18 = {
  key: 0,
  class: "body slim"
}
const _hoisted_19 = {
  key: 1,
  class: "body navigate"
}
const _hoisted_20 = {
  key: 2,
  class: "body empty"
}
const _hoisted_21 = { class: "body buttons" }
const _hoisted_22 = { class: "metadata" }
const _hoisted_23 = { class: "metadata-blocks" }
const _hoisted_24 = { class: "contributor-and-actions" }
const _hoisted_25 = { class: "contributor action-card" }
const _hoisted_26 = { class: "header" }
const _hoisted_27 = { class: "body" }
const _hoisted_28 = {
  key: 0,
  class: "collaboraters action-card"
}
const _hoisted_29 = { class: "header" }
const _hoisted_30 = {
  key: 0,
  class: "body names"
}
const _hoisted_31 = {
  key: 1,
  class: "body empty"
}
const _hoisted_32 = { class: "body buttons" }
const _hoisted_33 = { class: "actions action-card main" }
const _hoisted_34 = { class: "header" }
const _hoisted_35 = { class: "body buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_skeleton = _resolveComponent("o-skeleton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_MetadataRow = _resolveComponent("MetadataRow")!
  const _component_MetadataBlock = _resolveComponent("MetadataBlock")!
  const _component_AddCollaborater = _resolveComponent("AddCollaborater")!
  const _component_o_modal = _resolveComponent("o-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.metadata)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.metadata.imageBootstrap.summary), 1))
        : _createCommentVNode("", true),
      (!_ctx.metadata)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_4, [
            _createVNode(_component_o_skeleton, {
              size: "medium",
              width: "250px"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.metadata)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_o_skeleton, {
                size: "large",
                width: "20%"
              }),
              _createVNode(_component_o_skeleton, {
                height: "300px",
                width: "300px"
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_o_skeleton, {
                size: "large",
                width: "30%"
              }),
              _createVNode(_component_o_skeleton, {
                size: "medium",
                width: "50%"
              }),
              _createVNode(_component_o_skeleton, {
                size: "medium",
                width: "50%"
              }),
              _createVNode(_component_o_skeleton, {
                size: "medium",
                width: "50%"
              }),
              _createVNode(_component_o_skeleton, {
                size: "medium",
                width: "50%"
              }),
              _createVNode(_component_o_skeleton, {
                size: "medium",
                width: "50%"
              }),
              _createVNode(_component_o_skeleton, {
                size: "medium",
                width: "50%"
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_o_skeleton, {
                size: "large",
                width: "100%"
              }),
              _createVNode(_component_o_skeleton, {
                size: "large",
                width: "100%"
              }),
              _createVNode(_component_o_skeleton, {
                size: "large",
                width: "30%"
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.metadata)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t('read.image')), 1),
              _createElementVNode("div", _hoisted_12, [
                (_ctx.previewUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.previewUrl
                    }, null, 8, _hoisted_13))
                  : _createCommentVNode("", true),
                (!_ctx.previewUrl)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 1,
                      name: "image"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_o_button, {
                  class: "action-icon",
                  title: _ctx.$t('read.share'),
                  onClick: _ctx.copyToClipboard
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Icon, { name: "share" })
                  ]),
                  _: 1
                }, 8, ["title", "onClick"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_Icon, { name: "access_time" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('read.timeSeries')), 1)
                  ]),
                  (_ctx.metadata.imageInfo.timeSeries)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.$t('read.timeSeries')) + " : " + _toDisplayString(_ctx.metadata.imageInfo.timeSeriesId), 1))
                    : _createCommentVNode("", true),
                  (_ctx.metadata.imageInfo.timeSeries)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        _createVNode(_component_Icon, {
                          name: "arrow_back_ios",
                          onClick: _ctx.navigatePrevious,
                          title: _ctx.$t('read.previousInTimeSeries'),
                          class: _normalizeClass(_ctx.metadata.previousMetadataId ? '' : 'disabled')
                        }, null, 8, ["onClick", "title", "class"]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.metadata.currentIndex) + " / " + _toDisplayString(_ctx.metadata.seriesLength), 1),
                        _createVNode(_component_Icon, {
                          name: "arrow_forward_ios",
                          onClick: _ctx.navigateNext,
                          title: _ctx.$t('read.nextInTimeSeries'),
                          class: _normalizeClass(_ctx.metadata.nextMetadataId ? '' : 'disabled')
                        }, null, 8, ["onClick", "title", "class"])
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.metadata.imageInfo.timeSeries)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.$t('read.noTimeSeries')), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_21, [
                    (!_ctx.metadata.imageInfo.timeSeries)
                      ? (_openBlock(), _createBlock(_component_o_button, {
                          key: 0,
                          onClick: _ctx.turnIntoTimeSeries,
                          outlined: "",
                          disabled: !_ctx.metadata.permissions.canTurnIntoTimeSeries
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Icon, { name: "more_time" }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('read.turnIntoTimeSeries')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"]))
                      : _createCommentVNode("", true),
                    (_ctx.metadata.imageInfo.timeSeries)
                      ? (_openBlock(), _createBlock(_component_o_button, {
                          key: 1,
                          onClick: _ctx.addIntoTimeSerie,
                          outlined: "",
                          disabled: !_ctx.metadata.permissions.canAddIntoTimeSeries
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Icon, { name: "add" }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('read.addIntoTimeSerie')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t('read.metadata')), 1),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_MetadataBlock, {
                  id: "1",
                  label: _ctx.$t('contribute.step1.imageInfo'),
                  selected: _ctx.selected,
                  onClicked: _ctx.blockClicked
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step1.summary'),
                      value: _ctx.metadata.imageBootstrap.summary
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step1.description'),
                      value: _ctx.metadata.imageBootstrap.description
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step1.file'),
                      value: _ctx.metadata.file?.fileName
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step1.fileSize'),
                      value: _ctx.metadata.file?.fileSize,
                      formatter: _ctx.formatFileSize
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step1.mimeType'),
                      value: _ctx.metadata.file?.fileFormat
                    }, null, 8, ["label", "value"])
                  ]),
                  _: 1
                }, 8, ["label", "selected", "onClicked"]),
                _createVNode(_component_MetadataBlock, {
                  id: "2",
                  label: _ctx.$t('contribute.step2.title'),
                  selected: _ctx.selected,
                  onClicked: _ctx.blockClicked
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.dimension'),
                      value: _ctx.metadata.imageInfo.dimension,
                      "translate-prefix": "enums.ImageDimension."
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.captureTechnology'),
                      value: _ctx.metadata.imageInfo.captureTechnology,
                      "translate-prefix": "enums.CaptureTechnology."
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.captureTechnologyComplements'),
                      value: _ctx.metadata.imageInfo.captureTechnologyComplements
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.acquisitionDateTime'),
                      value: _ctx.metadata.imageInfo.acquisitionDateTime,
                      formatter: _ctx.formatDateTime
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.acquisitionSite'),
                      value: _ctx.metadata.imageInfo.acquisitionSite
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.bitsPerPixel'),
                      value: _ctx.metadata.imageInfo.bitsPerPixel
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.calibration'),
                      value: _ctx.metadata.imageInfo.calibration,
                      "translate-prefix": "forms.boolean_"
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.resolutionX'),
                      labelPrefix: _ctx.$t('contribute.step2.resolution'),
                      value: _ctx.metadata.imageInfo.resolutionX,
                      formatter: _ctx.appendResolutionUnit
                    }, null, 8, ["label", "labelPrefix", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.resolutionY'),
                      labelPrefix: _ctx.$t('contribute.step2.resolution'),
                      value: _ctx.metadata.imageInfo.resolutionY,
                      formatter: _ctx.appendResolutionUnit
                    }, null, 8, ["label", "labelPrefix", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.resolutionZ'),
                      labelPrefix: _ctx.$t('contribute.step2.resolution'),
                      value: _ctx.metadata.imageInfo.resolutionZ,
                      formatter: _ctx.appendResolutionUnit
                    }, null, 8, ["label", "labelPrefix", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.imageSizeX'),
                      labelPrefix: _ctx.$t('contribute.step2.imageSize'),
                      value: _ctx.metadata.imageInfo.imageSizeX
                    }, null, 8, ["label", "labelPrefix", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.imageSizeY'),
                      labelPrefix: _ctx.$t('contribute.step2.imageSize'),
                      value: _ctx.metadata.imageInfo.imageSizeY
                    }, null, 8, ["label", "labelPrefix", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.imageSizeZ'),
                      labelPrefix: _ctx.$t('contribute.step2.imageSize'),
                      value: _ctx.metadata.imageInfo.imageSizeZ
                    }, null, 8, ["label", "labelPrefix", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.timeSeries'),
                      value: _ctx.metadata.imageInfo.timeSeries,
                      "translate-prefix": "forms.boolean_"
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.timeSeriesId'),
                      value: _ctx.metadata.imageInfo.timeSeriesId
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.postAcquisitionTreatments'),
                      value: _ctx.metadata.imageInfo.postAcquisitionTreatments,
                      "translate-prefix": "forms.boolean_"
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.treatments'),
                      value: _ctx.metadata.imageInfo.treatments
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.purpose'),
                      value: _ctx.metadata.imageInfo.purpose
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step2.restrictions'),
                      value: _ctx.metadata.imageInfo.restrictions
                    }, null, 8, ["label", "value"])
                  ]),
                  _: 1
                }, 8, ["label", "selected", "onClicked"]),
                _createVNode(_component_MetadataBlock, {
                  id: "3",
                  label: _ctx.$t('contribute.step3.title'),
                  selected: _ctx.selected,
                  onClicked: _ctx.blockClicked
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.externalSourceBase'),
                      value: _ctx.metadata.imageSamplesInfo.externalSourceBase
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.externalEntryName'),
                      value: _ctx.metadata.imageSamplesInfo.externalEntryName
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.externalSampleId'),
                      value: _ctx.metadata.imageSamplesInfo.externalSampleId
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.externalComment'),
                      value: _ctx.metadata.imageSamplesInfo.externalComment
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.type'),
                      value: _ctx.metadata.imageSamplesInfo.type,
                      "translate-prefix": "enums.SampleType."
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.sampleTypeAdditionalInformation'),
                      value: _ctx.metadata.imageSamplesInfo.sampleTypeAdditionalInformation
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.shape'),
                      value: _ctx.metadata.imageSamplesInfo.shape,
                      "translate-prefix": "enums.Shape."
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.sampleSizeX'),
                      labelPrefix: _ctx.$t('contribute.step3.sampleSize'),
                      value: _ctx.metadata.imageSamplesInfo.sampleSizeX,
                      formatter: _ctx.appendCm
                    }, null, 8, ["label", "labelPrefix", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.sampleSizeY'),
                      labelPrefix: _ctx.$t('contribute.step3.sampleSize'),
                      value: _ctx.metadata.imageSamplesInfo.sampleSizeY,
                      formatter: _ctx.appendCm
                    }, null, 8, ["label", "labelPrefix", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.sampleSizeZ'),
                      labelPrefix: _ctx.$t('contribute.step3.sampleSize'),
                      value: _ctx.metadata.imageSamplesInfo.sampleSizeZ,
                      formatter: _ctx.appendCm
                    }, null, 8, ["label", "labelPrefix", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.samplingDateTime'),
                      value: _ctx.metadata.imageSamplesInfo.samplingDateTime,
                      formatter: _ctx.formatDateTime
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.coordinateWgs84X'),
                      labelPrefix: _ctx.$t('contribute.step3.coordinateWgs84'),
                      value: _ctx.metadata.imageSamplesInfo.coordinateWgs84X
                    }, null, 8, ["label", "labelPrefix", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.coordinateWgs84Y'),
                      labelPrefix: _ctx.$t('contribute.step3.coordinateWgs84'),
                      value: _ctx.metadata.imageSamplesInfo.coordinateWgs84Y
                    }, null, 8, ["label", "labelPrefix", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.coordinateWgs84Z'),
                      labelPrefix: _ctx.$t('contribute.step3.coordinateWgs84'),
                      value: _ctx.metadata.imageSamplesInfo.coordinateWgs84Z
                    }, null, 8, ["label", "labelPrefix", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.geolocationAccuracy'),
                      value: _ctx.metadata.imageSamplesInfo.geolocationAccuracy,
                      "translate-prefix": "enums.GeolocationAccuracy."
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.region'),
                      value: _ctx.metadata.imageSamplesInfo.region && _ctx.metadata.imageSamplesInfo.region['country_' + _ctx.$i18n.locale] + ' - ' + _ctx.metadata.imageSamplesInfo.region['region_' + _ctx.$i18n.locale]
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.lte'),
                      value: _ctx.metadata.imageSamplesInfo.lte
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.samplingMethod'),
                      value: _ctx.metadata.imageSamplesInfo.samplingMethod
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.samplingGuidance'),
                      value: _ctx.metadata.imageSamplesInfo.samplingGuidance,
                      "translate-prefix": "enums.SamplingGuidance."
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.samplingRowType'),
                      value: _ctx.metadata.imageSamplesInfo.samplingRowType,
                      "translate-prefix": "enums.SamplingRowType."
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step3.sampleComment'),
                      value: _ctx.metadata.imageSamplesInfo.sampleComment
                    }, null, 8, ["label", "value"])
                  ]),
                  _: 1
                }, 8, ["label", "selected", "onClicked"]),
                _createVNode(_component_MetadataBlock, {
                  id: "4",
                  label: _ctx.$t('contribute.step4.title'),
                  selected: _ctx.selected,
                  onClicked: _ctx.blockClicked
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.soilType'),
                      value: _ctx.metadata.imageSampleInstructiveContext.soilType
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.parentMaterial'),
                      value: _ctx.metadata.imageSampleInstructiveContext.parentMaterial
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.samplingDepthTop'),
                      value: _ctx.metadata.imageSampleInstructiveContext.samplingDepthTop
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.samplingDepthBottom'),
                      value: _ctx.metadata.imageSampleInstructiveContext.samplingDepthBottom
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.horizonType'),
                      value: _ctx.metadata.imageSampleInstructiveContext.horizonType,
                      "translate-prefix": "enums.HorizonType."
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.soilTillageHorizon'),
                      value: _ctx.metadata.imageSampleInstructiveContext.soilTillageHorizon,
                      "translate-prefix": "forms.boolean_"
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.textureClayPercent'),
                      value: _ctx.metadata.imageSampleInstructiveContext.textureClayPercent,
                      formatter: _ctx.appendPercent
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.textureSiltPercent'),
                      value: _ctx.metadata.imageSampleInstructiveContext.textureSiltPercent,
                      formatter: _ctx.appendPercent
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.textureSandPercent'),
                      value: _ctx.metadata.imageSampleInstructiveContext.textureSandPercent,
                      formatter: _ctx.appendPercent
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.pedologicalComments'),
                      value: _ctx.metadata.imageSampleInstructiveContext.pedologicalComments
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.landCover'),
                      value: _ctx.metadata.imageSampleInstructiveContext.landCover
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.culture'),
                      value: _ctx.metadata.imageSampleInstructiveContext.culture
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.soilTillage'),
                      value: _ctx.metadata.imageSampleInstructiveContext.soilTillage
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.lastTillage'),
                      value: _ctx.metadata.imageSampleInstructiveContext.lastTillage,
                      formatter: _ctx.formatDate
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.fertilizationType'),
                      value: _ctx.metadata.imageSampleInstructiveContext.fertilizationType
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.lastFertilization'),
                      value: _ctx.metadata.imageSampleInstructiveContext.lastFertilization,
                      formatter: _ctx.formatDate
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step4.agronomicComments'),
                      value: _ctx.metadata.imageSampleInstructiveContext.agronomicComments
                    }, null, 8, ["label", "value"])
                  ]),
                  _: 1
                }, 8, ["label", "selected", "onClicked"]),
                _createVNode(_component_MetadataBlock, {
                  id: "5",
                  label: _ctx.$t('contribute.step5.title'),
                  selected: _ctx.selected,
                  onClicked: _ctx.blockClicked
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.producer'),
                      value: _ctx.metadata.imageAdministrativeData.producer
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.distributor'),
                      value: _ctx.metadata.imageAdministrativeData.distributor
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.contact'),
                      value: _ctx.metadata.imageAdministrativeData.contact
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.contactEmail'),
                      value: _ctx.metadata.imageAdministrativeData.contactEmail
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.imageDoi'),
                      value: _ctx.metadata.imageAdministrativeData.imageDoi
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.imageComments'),
                      value: _ctx.metadata.imageAdministrativeData.imageComments
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.project'),
                      value: _ctx.metadata.imageAdministrativeData.project
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.funding'),
                      value: _ctx.metadata.imageAdministrativeData.funding
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.publications'),
                      value: _ctx.metadata.imageAdministrativeData.publications
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step5.contextComments'),
                      value: _ctx.metadata.imageAdministrativeData.contextComments
                    }, null, 8, ["label", "value"])
                  ]),
                  _: 1
                }, 8, ["label", "selected", "onClicked"]),
                _createVNode(_component_MetadataBlock, {
                  id: "6",
                  label: _ctx.$t('contribute.step6.title'),
                  selected: _ctx.selected,
                  onClicked: _ctx.blockClicked
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step6.persistentId'),
                      value: _ctx.metadata.persistentId
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step6.persistentUrl'),
                      value: _ctx.metadata.persistentUrl,
                      url: ""
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step6.dataverseUrl'),
                      value: _ctx.metadata.dataverseUrl,
                      url: ""
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step6.lastUpdate'),
                      value: _ctx.metadata.updateDate,
                      formatter: _ctx.formatDateTime
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step6.lastDataverseSynchro'),
                      value: _ctx.metadata.lastDataverseSynchro,
                      formatter: _ctx.formatDateTime
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step6.lastDataversePublication'),
                      value: _ctx.metadata.lastDataversePublication,
                      formatter: _ctx.formatDateTime
                    }, null, 8, ["label", "value", "formatter"]),
                    _createVNode(_component_MetadataRow, {
                      label: _ctx.$t('contribute.step6.dataversePublicAvailabilityDate'),
                      value: _ctx.metadata.dataversePublicAvailabilityDate,
                      formatter: _ctx.formatDateTime
                    }, null, 8, ["label", "value", "formatter"])
                  ]),
                  _: 1
                }, 8, ["label", "selected", "onClicked"])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_Icon, { name: "cloud_upload" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('read.contributor')), 1)
                ]),
                _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.metadata.author), 1)
              ]),
              (_ctx.metadata.permissions.canViewCollaboraters)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_Icon, { name: "group" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('read.collaboraters')), 1)
                    ]),
                    (_ctx.metadata.collaboraters.length >= 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadata.collaboraters, (collab) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: collab.userId
                              }, [
                                _createTextVNode(_toDisplayString(collab.firstName) + " " + _toDisplayString(collab.lastName) + " ", 1),
                                (_ctx.metadata.permissions.canAddCollaborater)
                                  ? (_openBlock(), _createBlock(_component_Icon, {
                                      key: 0,
                                      name: "delete",
                                      onClick: ($event: any) => (_ctx.deleteCollaborater(collab.userId)),
                                      title: _ctx.$t('read.deleteCollaborater')
                                    }, null, 8, ["onClick", "title"]))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.metadata.collaboraters.length == 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(_ctx.$t('read.noCollaborater')), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_o_button, {
                        onClick: _ctx.addCollaborater,
                        outlined: "",
                        disabled: !_ctx.metadata.permissions.canAddCollaborater
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, { name: "add" }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('read.addCollaborater')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "disabled"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _createVNode(_component_Icon, { name: "settings" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('read.actions')), 1)
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _createVNode(_component_o_button, {
                    onClick: _ctx.updateContribution,
                    outlined: "",
                    disabled: !_ctx.metadata.permissions.canModify
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, { name: "edit" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('read.update')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"]),
                  _createVNode(_component_o_button, {
                    onClick: _ctx.deleteContribution,
                    outlined: "",
                    disabled: !_ctx.metadata.permissions.canDelete
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, { name: "delete" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('read.delete')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"]),
                  _createVNode(_component_o_button, {
                    onClick: _ctx.duplicate,
                    outlined: "",
                    disabled: !_ctx.metadata.permissions.canDuplicate
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, { name: "content_copy" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('read.duplicate')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"]),
                  _createVNode(_component_o_button, {
                    onClick: _ctx.downloadContribution,
                    outlined: "",
                    disabled: !_ctx.metadata.permissions.canDownloadFile
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, { name: "file_download" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('read.download')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"]),
                  _createVNode(_component_o_button, {
                    onClick: _ctx.publishMetadata,
                    outlined: "",
                    disabled: !_ctx.metadata.metadataPublishable || !_ctx.metadata.permissions.canPublish
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, { name: "publish" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('read.publishMetadata')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"]),
                  _createVNode(_component_o_button, {
                    onClick: _ctx.publishFile,
                    outlined: "",
                    disabled: !_ctx.metadata.filePublishable || !_ctx.metadata.permissions.canPublish
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, { name: "publish" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('read.publishFile')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_o_modal, {
      active: _ctx.addCollaboraterModalActive,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addCollaboraterModalActive) = $event)),
      scroll: "clip"
    }, {
      default: _withCtx(() => [
        (_ctx.addCollaboraterModalActive)
          ? (_openBlock(), _createBlock(_component_AddCollaborater, {
              key: 0,
              onAdded: _ctx.newOneAdded,
              metadataId: _ctx.id
            }, null, 8, ["onAdded", "metadataId"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}