const resource = {
  "authentication": {
    "notConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not connected"])},
    "connectedAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to the token you are"])},
    "withEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and according to the backend you're email is: "])}
  },
  "layout": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch language"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any feedback?"])},
    "realizedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realized by"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data entry help"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "cgu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])}
  },
  "forms": {
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory"])},
    "additionalFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional fields"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "saveAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and New contribution"])},
    "saveAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and Close"])},
    "boolean_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "boolean_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "boolean_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "errors": {
      "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory"])},
      "this must be a positive number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this must be a positive number"])},
      "incoherent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoherent value"])}
    }
  },
  "home": {
    "searchVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search the database according to metadata"])},
    "contributeVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute"])},
    "contributeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload an image to the database"])},
    "availableSamplesPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "availableSamplesBold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " image samples"])},
    "availableSamplesSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "presentation1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A shared database for storing and sharing soil images "])},
    "presentation1Content0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database has two major objectives: to facilitate the storage of soil images and to promote their sharing. We thus hope to simplify the exchange of data within research projects, encourage the reuse of images that would have already been used elsewhere, help the initiation of new collaborations and the development of research projects in soil science."])},
    "presentation1Content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The images are stored with their metadata (context of obtaining, mode of acquisition of images, processing already carried out if necessary, etc.) and are automatically provided with a DOI to facilitate their identification."])},
    "presentation2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A French database available to the international scientific community"])},
    "presentation2Content0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database is the initiative of a group of French researchers, teacher-researchers and engineers, and its creation was funded thanks to the AgroEcosystem department of INRAE."])},
    "presentation2Content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Its purpose is to host images produced by both French and international research teams."])}
  },
  "search": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "filters": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "doi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOI"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributor"])},
      "onlyComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only complete contributions"])},
      "selfAuthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My contributions"])},
      "selfCollaborater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared with me"])},
      "timeSeriesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time series ID"])}
    },
    "applyFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply filters"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No match found, you should update your filters"])},
    "use-the-filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To start search"])},
    "use-the-filters-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To (re)start search"])},
    "notAuthenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to authenticate before you search for contributions"])},
    "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication"])},
    "metadataAvailableOnDataverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata are published"])},
    "fileAvailableOnDataverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is published"])}
  },
  "contribute": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute"])},
    "updateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update contribution"])},
    "contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributor"])},
    "persistentUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistent URL"])},
    "confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel? Your changes will be lost."])},
    "confirmCancelAndDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel? Your contribution will be deleted."])},
    "dropped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution deleted"])},
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An attriute has an invalid format, please check the value"])},
    "invalidFormatField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid format"])},
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bootstrap"])},
      "fileUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image upload"])},
      "imageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image information"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image file"])},
      "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size"])},
      "mimeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filte type"])},
      "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "fileVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File verification"])},
      "fileVerificationOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The linked import file is ok and have been loaded for contribution."])},
      "fileVerificationKO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The linked import file is not ok and have not been loaded for contribution."])},
      "informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
      "notAuthenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to authenticate before you contribute"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New time series"])},
      "existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing time series"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "uploadTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a time series, it is possible to upload one image at a time, or a zip file gathering serveral images"])},
      "seeExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the file template"])},
      "timeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time series"])},
      "timeSeriesQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is your contribution a part of a time series ?"])},
      "timeSeriesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos"])},
      "infoModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time series contribution"])},
        "imageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a time series, it is possible to upload one image at a time (allowed formats : .png, .jpg, .gif)."])},
        "zipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also upload a compressed file (only .zip format) gathering several images (allowed formats : .png, .jpg, .gif) and a file compiling metadata (only .csv format)."])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "zipDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download .zip template"])}
      },
      "returnToForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to previous step"])}
    },
    "step2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical data"])},
      "acquisitionSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image acquisition"])},
      "acquisition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition"])},
      "treatmentsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatments carried out on the image after its acquisition "])},
      "acquisitionDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition date & time"])},
      "acquisitionSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition site"])},
      "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image properties"])},
      "dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimension"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
      "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolution"])},
      "resolutionX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
      "resolutionXFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X (Resolution)"])},
      "resolutionY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
      "resolutionYFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y (Resolution)"])},
      "resolutionZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
      "resolutionZFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z (Resolution)"])},
      "resolutionUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolution unit"])},
      "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image size (number of pixels)"])},
      "imageSizeX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
      "imageSizeXFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X (Image size)"])},
      "imageSizeY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
      "imageSizeYFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y (Image size)"])},
      "imageSizeZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
      "imageSizeZFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z (Image size)"])},
      "timeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time series"])},
      "timeSeriesTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To initialize a time series, you must first initialize it by creating a first contribution (without specifying that it is a time series), then transform it into a time series via the contribution visualization page (accessible via the Search tool)."])},
      "timeSeriesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time series ID"])},
      "timeSeriesIdTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time series identifiers are automatically generated by the application. To be able to fill in the « Time series ID » field, the time series must have been initiated beforehand."])},
      "timeSeriesIdNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown time series"])},
      "timeSeriesIdNotFoundLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must indicate the identifier of an existing time series"])},
      "bitsPerPixel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bits per pixel"])},
      "postAcquisitionTreatments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-acquisition treatments"])},
      "treatments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatments description"])},
      "purposeAndRestrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose and restrictions"])},
      "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose / possible uses (technically)"])},
      "restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitation / use restrictions (technically)"])},
      "captureTechnologyComplements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precise..."])},
      "captureTechnology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device used for acquisition"])},
      "captureTechnologySpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characteristics of the device and parameters used during the acquisition"])},
      "captureTechnologySpecificationsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device brand and model, energy level and current for an X-ray tomograph, etc."])}
    },
    "step3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptive data : sample"])},
      "sampleSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample"])},
      "externalSourceBaseSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External database"])},
      "externalSourceBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External database name"])},
      "externalSourceBaseTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If data concerning your sample is already in another database (external to ImageSol), you can indicate here how to find this information"])},
      "externalEntryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the field used to identify your sample in the external database"])},
      "externalSampleId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample ID in the external database"])},
      "externalComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "externalCommentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments (External database)"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample type"])},
      "sampleTypeAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information about the sample type"])},
      "shape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample shape"])},
      "sampleSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample size (spatial extension) (cm)"])},
      "sampleSizeX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X or diameter"])},
      "sampleSizeXFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X or diameter (Sample size)"])},
      "sampleSizeY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y or diameter"])},
      "sampleSizeYFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y or diameter (Sample size)"])},
      "sampleSizeZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
      "sampleSizeZFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z (Sample size)"])},
      "sampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling"])},
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taking"])},
      "samplingContextSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling context"])},
      "samplingDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling date & time"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "lte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LTEs (Long Term field Experiments) name (if applicable)"])},
      "samplingMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling method"])},
      "samplingGuidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling guidance"])},
      "samplingRowType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-row or inter-row sampling"])},
      "sampleComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "sampleCommentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments (Taking)"])}
    },
    "step4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptive data : context"])},
      "pedologicContextSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedological context"])},
      "pedologicData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information (pedological)"])},
      "pedologicSampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling"])},
      "soilType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soil Type (World Reference Base – WRB)"])},
      "soilTypeOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soil type (other soil classification system)"])},
      "soilTypeOtherClassificationSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify the soil classification system used"])},
      "parentMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent material"])},
      "samplingDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling depth"])},
      "samplingDepthBottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower"])},
      "samplingDepthBottomFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower (Sampling depth)"])},
      "samplingDepthTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper"])},
      "samplingDepthTopFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper (Sampling depth)"])},
      "horizonType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soil horizon (diagnostic horizon WRB)"])},
      "soilTillageHorizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soil tillage horizon"])},
      "pedologicTextures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textures"])},
      "textures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textures"])},
      "textureClayPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clay"])},
      "textureClayPercentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clay (Texture)"])},
      "textureSiltPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silt"])},
      "textureSiltPercentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silt (Texture)"])},
      "textureSandPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sand"])},
      "textureSandPercentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sand (Texture)"])},
      "textureCarbonPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon"])},
      "textureCarbonPercentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon (%)"])},
      "texturePh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
      "texturePhFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
      "pedologicalComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "pedologicalCommentsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments (Textures)"])},
      "agronomicContextSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agronomic context"])},
      "agronomicData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information (agronomic)"])},
      "landCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land cover at the time of sampling (Corine Land Cover classification)"])},
      "culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture at sampling time"])},
      "soilTillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soil tillage type"])},
      "lastTillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of last tillage"])},
      "fertilizationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of fertilization"])},
      "lastFertilization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of last fertilization"])},
      "agronomicComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "agronomicCommentsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments (Agronomic context)"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice"])},
      "practiceDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice depth"])}
    },
    "step5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative Data"])},
      "rightsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image identification and usage rights"])},
      "identification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image identification"])},
      "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the person who acquired the image"])},
      "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributor name"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of contact person"])},
      "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact (email address)"])},
      "previousPublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous publication of the image"])},
      "previousPublicationDoi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication DOI"])},
      "previousPublicationComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "previousPublicationCommentsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments (Image identification)"])},
      "scientistContextSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scientific context of the acquisition"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
      "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "contextComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "contextCommentsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments (Scientific context of the acquisition)"])}
    },
    "step6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davaserve synchronization"])},
      "persistentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOI"])},
      "persistentUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistent URL"])},
      "dataverseUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dataverse URL"])},
      "lastDataverseSynchro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Dataverse synchronization"])},
      "lastDataversePublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata publication"])},
      "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update on ImageSol"])},
      "dataversePublicAvailabilityDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File publication"])}
    },
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'r contribution is now created!"])},
    "updateComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'r contribution is now updated!"])},
    "upload": {
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload(s) in progress"])}
    },
    "tsuc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change detected within a time series"])},
      "singleField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A metadata has been modified:"])},
      "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Several metadata have been modified, namely:"])},
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contribution is part of a time series. You can choose to apply these changes only to this contribution or to all contributions in the time series."])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "onlyContribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply only to this contribution"])},
      "spreadToTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propagate to all the time teries"])}
    }
  },
  "read": {
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
    "contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributor"])},
    "collaboraters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboraters"])},
    "noCollaborater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No collaborater"])},
    "addCollaborater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "deleteCollaborater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke sharing"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "downloadMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downlad metadata"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied into the clipboard"])},
    "notAuthenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to authenticate before you can read this contribution"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
    "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution duplicated"])},
    "publishMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish metadata"])},
    "metadataPusblished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata published"])},
    "publishFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish file"])},
    "filePusblished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File published"])},
    "turnIntoTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "addIntoTimeSerie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "turnedIntoTimeSerie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time series created"])},
    "timeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time series"])},
    "noTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time series"])},
    "previousInTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous contribution"])},
    "nextInTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next contribution"])},
    "duplicatedForTimeSerie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution duplicated for the time series"])}
  },
  "partners": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What data can be stored?"])},
    "answer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soil images, whatever their acquisition mode, whether 2D or 3D."])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can upload data?"])},
    "answer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any user connected to the application."])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am not an INRAE staff member or I’m not French, can I access the database to submit images and download them?"])},
    "answer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, absolutely."])},
    "question4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With which account can I identify myself?"])},
    "answer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are different ways to log in: Ldap identifiers for INRAE users, ORCID account, creation of an account via the application."])},
    "question5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who do I contact to open a user account?"])},
    "answer5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create an account yourself via the application, by going to the login page of the application."])},
    "question6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are the images and their metadata stored?"])},
    "answer6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They are stored in INRAE datacenters."])},
    "question7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can everyone have access to the data that I drop on the database?"])},
    "answer7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any user can see that your data is part of the database and view its metadata. At first you can choose who can download your data: only you, a group of users, any user. You choose yourself when your images are published and become public (and therefore freely downloadable). A reminder will be sent to you by email every 6 months to encourage you to publish your images. Your images will be published automatically and will therefore become public 5 years after their initial submission."])},
    "question8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I cite images that I upload and reuse?"])},
    "answer8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each image has a Digital Object Identifier (DOI), which must be cited when reusing. This DOI is of the following form “doi:10.70112/XXXXXX” and can be found in the image metadata."])},
    "question9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If I cannot fill in mandatory fields, can my images still be part of the database?"])},
    "answer9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The forms used to fill in the metadata include mandatory fields and optional fields. We have made sure to reduce the mandatory fields to the minimum necessary, but they must be filled in for your images to be included in the database."])},
    "question10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quoi correspondent les erreurs lors de l'envoi d'une série temporelle ?"])},
    "answer10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Several messages can indicate an error while sending a time series: \"No CSV file in import archive\": you missed the csv file in the archive; \"Missing file in archive\": an image referenced in the csv file is missing; \"Error in the CSV file\": The CSV file is badly written, the message indicates the error.; \"File is too big, max size is xx Mb\" : An image is too big for the platform."])}
  },
  "collaboraters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a collaborater"])},
    "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type as least 2 letters to start searching"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborater added"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborater removed"])}
  },
  "enums": {
    "ImageDimension": {
      "TWO_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D"])},
      "THREE_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D"])}
    },
    "ResolutionUnit": {
      "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cm"])},
      "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mm"])},
      "um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["µm"])}
    },
    "CaptureTechnology": {
      "MEDICAL_SCANNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical scanner"])},
      "INDUSTRIAL_SCANNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial scanner"])},
      "TWO_D_SCANNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D scanner"])},
      "MICROSCOPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microscope"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "SampleType": {
      "NATURAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural"])},
      "REPACKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repacked"])},
      "RECONSTITUTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconstitued"])}
    },
    "Shape": {
      "CYLINDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylinder"])},
      "CUBE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cube"])},
      "AGGREGATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggregate"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "HorizonType": {
      "H": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H"])},
      "O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
      "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
      "C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      "R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])}
    },
    "GeolocationAccuracy": {
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "LOCATION_OF_THE_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location of the sample"])},
      "PLOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plot"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "SamplingGuidance": {
      "HORIZONTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
      "VERTICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "SamplingRowType": {
      "ROW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-row"])},
      "INTER_ROW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inter-row"])}
    },
    "LandCoverType": {
      "URBAN_FABRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urban fabric"])},
      "INDUSTRIAL_COMMERCIAL_AND_TRANSPORT_UNITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial, commercial and transport units"])},
      "MINE_DUMP_AND_CONSTRUCTION_SITES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine, dump and construction sites"])},
      "ARTIFICIAL_NON_AGRICULTURAL_VEGETATED_AREAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artificial, non-agricultural vegetated areas"])},
      "ARABLE_LAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arable land"])},
      "PERMANENT_CROPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent crops"])},
      "PASTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastures"])},
      "HETEROGENEOUS_AGRICULTURAL_AREAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heterogeneous agricultural areas"])},
      "FORESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forests"])},
      "SCRUB AND_OR_HERBACEOUS VEGETATION_ASSOCIATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrub and/or herbaceous vegetation associations"])},
      "OPEN_SPACE_WITH_LITTLE_OR_NO_VEGETATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open spaces with little or no vegetation"])},
      "INLAND_WETLANDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inland wetlands"])},
      "MARITIME_WETLANDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maritime wetlands"])},
      "INLAND_WATERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inland waters"])},
      "MARINE_WATERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marine waters"])}
    },
    "Practice": {
      "PLOUGHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ploughing"])},
      "SUPERFICIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficial work"])},
      "NONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No soil work"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    }
  }
}
export default resource