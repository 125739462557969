const resource = {
  "authentication": {
    "notConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas connecté⋅e"])},
    "connectedAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'après le token vous êtes"])},
    "withEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et d'après le backend votre email est : "])}
  },
  "layout": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuer"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon profil"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la langue"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un bug ? Une remarque ?"])},
    "realizedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisé par"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide à la saisie"])},
    "cgu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions Générales d'Utilisation"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])}
  },
  "forms": {
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
    "additionalFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs complémentaires"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "saveAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider et Nouvelle contribution"])},
    "saveAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider et Fermer"])},
    "boolean_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "boolean_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "boolean_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "errors": {
      "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
      "this must be a positive number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nombre doit être positif"])},
      "incoherent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur incohérente"])}
    }
  },
  "home": {
    "searchVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "searchDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une recherche dans la base de données selon métadonnées"])},
    "contributeVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuer"])},
    "contributeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser une image dans la base de données"])},
    "availableSamplesPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualisation des "])},
    "availableSamplesBold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " échantillons d’images"])},
    "availableSamplesSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibles"])},
    "presentation1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une base de données mutualisée pour le stockage et le partage d’images de sol"])},
    "presentation1Content0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données a deux objectifs majeurs : faciliter le stockage d’images de sol et promouvoir leur partage. Nous espérons ainsi simplifier les échanges de données au sein de projets de recherche, encourager la réutilisation d’images qui auraient déjà été valorisées par ailleurs, aider à l’initiation de nouvelles collaborations et au développement de projets de recherche en science du sol."])},
    "presentation1Content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les images sont stockées avec leurs métadonnées (contexte d’obtention, mode d’acquisition des images, traitements déjà réalisés si nécessaire, etc.) et sont automatiquement pourvues d’un DOI pour faciliter leur identification."])},
    "presentation2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une base de données française à disposition de la communauté scientifique internationale"])},
    "presentation2Content0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données est à l’initiative d’un groupe de chercheurs, enseignants-chercheurs et ingénieurs français, et sa création a été financée grâce au département AgroEcosystem d’INRAE."])},
    "presentation2Content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elle a pour vocation d’accueillir des images produites tant par les équipes de recherche françaises qu’internationales."])}
  },
  "search": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "filters": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
      "doi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOI"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
      "onlyComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributions complètes uniquement"])},
      "selfAuthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes contributions"])},
      "selfCollaborater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagées avec moi"])},
      "timeSeriesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de série temporelle"])}
    },
    "applyFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer les filtres"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultat"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
    "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune image ne correspond à vos critères, veuillez modifier vos filtres"])},
    "use-the-filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour lancer la recherche"])},
    "use-the-filters-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour (re)lancer la recherche"])},
    "notAuthenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous authentifier avant de pouvoir utiliser la recherche"])},
    "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication"])},
    "metadataAvailableOnDataverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les métadonnées sont publiées"])},
    "fileAvailableOnDataverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier est publié"])}
  },
  "contribute": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuer"])},
    "updateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la contribution"])},
    "contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributeur"])},
    "persistentUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL persistante"])},
    "confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr⋅e de vouloir annuler ? Vos modifications seront perdues."])},
    "confirmCancelAndDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr⋅e de vouloir annuler ? Votre contribution sera supprimée."])},
    "dropped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution supprimée"])},
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un champ est mal formatté, veuillez vérifier la valeur"])},
    "invalidFormatField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format incorrect"])},
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entête"])},
      "fileUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt de l’image"])},
      "imageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de l’image"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier de l’image"])},
      "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du fichier"])},
      "mimeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier"])},
      "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcourir"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "fileVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du fichier"])},
      "fileVerificationOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier d'import joint est correct et a été chargé à la contribution."])},
      "fileVerificationKO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier d'import joint est incorrect et n'a pas pu être chargé à la contribution."])},
      "informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
      "notAuthenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous authentifier avant de pouvoir contribuer"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle série temporelle"])},
      "existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série temporelle existante"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
      "uploadTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour une série temporelle il est possible de téléverser une image à la fois, ou un fichier .zip regroupant plusieurs images"])},
      "seeExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le modèle de fichier attendu"])},
      "timeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série temporelle"])},
      "timeSeriesQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre contribution fait-elle partie d'une série temporelle ?"])},
      "timeSeriesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos"])},
      "infoModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution d'une série temporelle"])},
        "imageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour une série temporelle, il est possible de téléverser une image à la fois (formats acceptés : .png, .jpg, .gif)."])},
        "zipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez également téléverser un fichier compressé (format .zip uniquement) regroupant plusieurs images (formats acceptés : .png, .jpg, .gif) ainsi qu'un fichier compilant les métadonnées (format .csv uniquement)."])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "zipDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le modèle .zip"])}
      },
      "returnToForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'étape précédente"])}
    },
    "step2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données techniques"])},
      "acquisitionSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition de l’image"])},
      "acquisition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition"])},
      "treatmentsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitements réalisés sur l’image après son acquisition"])},
      "acquisitionDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure d’acquisition"])},
      "acquisitionSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d’acquisition"])},
      "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétés de l’image"])},
      "dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimension"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
      "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolution"])},
      "resolutionX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
      "resolutionXFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X (Résolution)"])},
      "resolutionY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
      "resolutionYFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y (Résolution)"])},
      "resolutionZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
      "resolutionZFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z (Résolution)"])},
      "resolutionUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité de la résolution"])},
      "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille de l’image (nombre de pixels)"])},
      "imageSizeX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
      "imageSizeXFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X (Taille de l’image)"])},
      "imageSizeY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
      "imageSizeYFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y (Taille de l’image)"])},
      "imageSizeZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
      "imageSizeZFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z (Taille de l’image)"])},
      "timeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série temporelle"])},
      "timeSeriesTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour initialiser une série temporelle, il faut d’abord l’initialiser en créant une première contribution (sans spécifier qu'il s'agit d'une série temporelle), puis la transformer en série temporelle via la page de visualisation de la contribution (accessible via l’outils de Recherche)."])},
      "timeSeriesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de la série temporelle"])},
      "timeSeriesIdTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les identifiants de série temporelle sont générés automatiquement par l’application. Pour pouvoir renseigner le champ « Identifiant de la série temporelle », il faut que la série temporelle ait été initiée au préalable."])},
      "timeSeriesIdNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série temporelle inconnue"])},
      "timeSeriesIdNotFoundLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez indiquer l'identifiant d'une série temporelle existante"])},
      "bitsPerPixel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bits par pixel"])},
      "postAcquisitionTreatments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitements après acquisition"])},
      "treatments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description des traitements"])},
      "purposeAndRestrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination & limitations"])},
      "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination/usages possibles (techniquement)"])},
      "restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitation/restrictions d’usages (techniquement)"])},
      "captureTechnologyComplements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précisez ..."])},
      "captureTechnology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareil utilisé pour l'acquisition"])},
      "captureTechnologySpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques de l'appareil et paramètres utilisés lors de l’acquisition"])},
      "captureTechnologySpecificationsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque et modèle de l’appareil, niveau d’énergie et courant pour un tomographe à rayons-X, etc."])}
    },
    "step3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données descriptives : Échantillon"])},
      "sampleSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon"])},
      "externalSourceBaseSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens avec une autre base de donnée"])},
      "externalSourceBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la base de donnée externe"])},
      "externalSourceBaseTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si des données concernant votre échantillon sont déjà renseignées dans une autre base de donnée (externe à ImageSol), vous pouvez indiquer ici comment retrouver ces informations"])},
      "externalEntryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du champ permettant d’identifier votre échantillon dans la base externe"])},
      "externalSampleId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de votre échantillon dans la base externe"])},
      "externalComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "externalCommentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires (Base externe)"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature de l’échantillon"])},
      "sampleTypeAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléments sur la nature de l’échantillon"])},
      "shape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme de l’échantillon"])},
      "sampleSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille de l’échantillon (extension spatiale) (cm)"])},
      "sampleSizeX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X ou diamètre"])},
      "sampleSizeXFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X ou diamètre (Taille de l’échantillon)"])},
      "sampleSizeY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y ou diamètre"])},
      "sampleSizeYFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y ou diamètre (Taille de l’échantillon)"])},
      "sampleSizeZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
      "sampleSizeZFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z (Taille de l’échantillon)"])},
      "sampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillonnage"])},
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prélèvement"])},
      "samplingContextSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexte d’échantillonnage"])},
      "samplingDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure d’échantillonnage"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation de l’échantillon"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
      "lte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du Site d'expérimentation à long terme (si pertinent)"])},
      "samplingMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de prélèvement"])},
      "samplingGuidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientation de prélèvement"])},
      "samplingRowType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prélèvement dans le rang ou en inter-rang"])},
      "sampleComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "sampleCommentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires (Prélèvement)"])}
    },
    "step4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données descriptives : Contexte"])},
      "pedologicContextSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexte pédologique"])},
      "pedologicData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations complémentaires (pédologique)"])},
      "pedologicSampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillonnage"])},
      "pedologicTextures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composition"])},
      "soilType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de sol (World Reference Base – WRB)"])},
      "soilTypeOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de sol (autre système de classification des sols)"])},
      "soilTypeOtherClassificationSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préciser le système de classification des sols utilisé"])},
      "parentMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériau parental"])},
      "samplingDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeurs d’échantillonnage (cm)"])},
      "samplingDepthBottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inférieure"])},
      "samplingDepthBottomFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inférieure (Profondeur d’échantillonnage)"])},
      "samplingDepthTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supérieure"])},
      "samplingDepthTopFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supérieure (Profondeur d’échantillonnage)"])},
      "horizonType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon de sol (horizon diagnostique WRB)"])},
      "soilTillageHorizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon de travail du sol"])},
      "textures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textures (%)"])},
      "textureClayPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argile"])},
      "textureClayPercentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argile (Texture)"])},
      "textureSiltPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limon"])},
      "textureSiltPercentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limon (Texture)"])},
      "textureSandPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sable"])},
      "textureSandPercentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sable (Texture)"])},
      "textureCarbonPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teneur en carbone"])},
      "textureCarbonPercentFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teneur en carbone (%)"])},
      "texturePh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
      "texturePhFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
      "pedologicalComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "pedologicalCommentsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires (Composition)"])},
      "agronomicContextSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexte agronomique"])},
      "agronomicData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations complémentaires (agronomique)"])},
      "landCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation du sol au moment du prélèvement (nomenclature Corine Land Cover)"])},
      "culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture au moment du prélèvement"])},
      "soilTillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de travail du sol"])},
      "lastTillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du dernier travail"])},
      "fertilizationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fertilisation"])},
      "lastFertilization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la dernière fertilisation"])},
      "agronomicComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "agronomicCommentsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires (Contexte agronomique)"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratique"])},
      "practiceDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur"])}
    },
    "step5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données administratives"])},
      "rightsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification de l’image et droits d’utilisation"])},
      "identification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification de l’image"])},
      "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la personne ayant acquis l’image"])},
      "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du contributeur"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la personne contact"])},
      "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact (adresse mail)"])},
      "previousPublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication antérieure de l’image"])},
      "previousPublicationDoi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOI de la publication"])},
      "previousPublicationComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "previousPublicationCommentsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires (Identification de l’image)"])},
      "scientistContextSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexte scientifique de l’acquisition"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet"])},
      "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financement"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "contextComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "contextCommentsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires (Contexte scientifique de l’acquisition)"])}
    },
    "step6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisation avec le Dataverse"])},
      "persistentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOI"])},
      "persistentUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistent URL"])},
      "dataverseUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL sur le Dataverse"])},
      "lastDataverseSynchro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière synchronisation sur le dataverse"])},
      "lastDataversePublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication des métadonnées"])},
      "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification sur l'application"])},
      "dataversePublicAvailabilityDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication du fichier"])}
    },
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La création de votre contribution est terminée !"])},
    "updateComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La modification de votre contribution est terminée !"])},
    "upload": {
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement(s) en cours"])}
    },
    "tsuc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement détecté au sein d'une série temporelle"])},
      "singleField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une métadonnée ont été modifiée :"])},
      "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs métadonnées ont été modifiées, à savoir :"])},
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre contribution fait partie d'une série temporelle. Vous pouvez choisir d'appliquer ces changements seulement à cette contribution ou à toutes les contributions de la série temporelle."])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "onlyContribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer seulement à cette contribution"])},
      "spreadToTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propager à la série temporelle"])}
    }
  },
  "read": {
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métadonnées"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributeur"])},
    "collaboraters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs"])},
    "noCollaborater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de collaborateur"])},
    "addCollaborater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "deleteCollaborater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révoquer le partage"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr⋅e de vouloir supprimer la contribution ?"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "downloadMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les métadonnées"])},
    "cannotDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'obtenir une URL de téléchargement"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien copié dans le presse-papier"])},
    "notAuthenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous authentifier avant de pouvoir consulter cette contribution"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
    "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contribution a été dupliquée"])},
    "publishMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier les métadonnées"])},
    "metadataPusblished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métadonnées publiées"])},
    "publishFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier le fichier"])},
    "filePusblished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier publié"])},
    "turnIntoTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
    "addIntoTimeSerie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "turnedIntoTimeSerie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série temporelle créée"])},
    "timeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série temporelle"])},
    "noTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de série temporelle"])},
    "previousInTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution précédente"])},
    "nextInTimeSeries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution suivante"])},
    "duplicatedForTimeSerie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution dupliquée pour la série temporelle"])}
  },
  "partners": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaires"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foire Aux Questions"])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles sont les données qui peuvent être stockées ?"])},
    "answer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des images de sol, quel que soit leur mode d’acquisition, qu’elles soient 2D ou 3D."])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui peut déposer des données ?"])},
    "answer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout utilisateur connecté à l’application."])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne suis pas personnel INRAE ou je ne suis pas français, puis je accéder à la base pour soumettre des images et en télécharger ?"])},
    "answer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, tout à fait."])},
    "question4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec quel compte puis-je m’identifier ?"])},
    "answer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a différentes manières de se loguer : identifiants Ldap les utilisateurs INRAE, compte ORCID, création d’un compte via l’application."])},
    "question5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A qui s’adresser pour ouvrir un compte utilisateur ?"])},
    "answer5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez vous-même créer un compte via l’application, en vous rendant sur la page de connexion de l’application."])},
    "question6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où sont stockées les images et leurs métadonnées ?"])},
    "answer6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les images et leurs métadonnées sont stockées sur les datacenter INRAE."])},
    "question7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout le monde peut-il avoir accès aux données que je dépose sur la base de données ?"])},
    "answer7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout utilisateur peut voir que vos données font parties de la base de données et visualiser leurs métadonnées. Au début vous pouvez choisir qui peut télécharger vos données : vous uniquement, un groupe d’utilisateur, n’importe quel utilisateur. Vous choisissez vous-même quand vos images sont publiées et deviennent publiques (et donc téléchargeables librement). Un rappel vous sera envoyé par mail tous les 6 mois pour vous inciter à publier vos images. Vos images seront publiées automatiquement et deviendront donc publiques 5 ans après leur dépôt initial."])},
    "question8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment citer les images que je télécharge et réutilise ?"])},
    "answer8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque image possède un identifiant numérique d'objet (DOI, pour « Digital Object Identifier »), qui doit être cité lors de toute réutilisation. Ce DOI est de la forme suivante « doi:10.70112/XXXXXX » et peut être trouvé dans les métadonnées de l’image."])},
    "question9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si je ne peux remplir des champs obligatoires, mes images peuvent-elles faire partie tout de même de la base ?"])},
    "answer9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les formulaires permettant de renseigner les métadonnées comporte des champs obligatoires et des champs facultatifs. Nous avons fait en sorte de réduire les champs obligatoires au minimum nécessaire, mais ils doivent être impérativement renseignés pour que vos images soient inclues dans la base de données."])},
    "question10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quoi correspondent les erreurs lors de l'envoi d'une série temporelle ?"])},
    "answer10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs messages peuvent vous indiquer une erreur lors de l'envoi d'une série temporelle :\"No CSV file in import archive\" : vous n'avez pas de fichier CSV dans l'archive ; \"Missing file in archive\" : il manque une image référencée dans le fichier CSV ; \"Error in the CSV file\" : Le fichier CSV est mal formé, la suite du message vous indique l'erreur (en anglais); \"File is too big, max size is xx Mb\" : Une image est trop grosse pour la plateforme."])}
  },
  "collaboraters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un collaborateur"])},
    "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez au moins 2 lettres pour commencer la recherche"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateur ajouté"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateur supprimé"])}
  },
  "enums": {
    "ImageDimension": {
      "TWO_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D"])},
      "THREE_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D"])}
    },
    "ResolutionUnit": {
      "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cm"])},
      "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mm"])},
      "um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["µm"])}
    },
    "CaptureTechnology": {
      "MEDICAL_SCANNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner médical"])},
      "INDUSTRIAL_SCANNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner industriel"])},
      "TWO_D_SCANNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner 2D"])},
      "MICROSCOPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microscope"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
    },
    "SampleType": {
      "NATURAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naturel"])},
      "REPACKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remanié"])},
      "RECONSTITUTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconstitué"])}
    },
    "Shape": {
      "CYLINDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylindre"])},
      "CUBE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cube"])},
      "AGGREGATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrégat"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
    },
    "HorizonType": {
      "H": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H"])},
      "O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
      "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
      "C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      "R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])}
    },
    "GeolocationAccuracy": {
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commune"])},
      "LOCATION_OF_THE_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation de l’échantillon"])},
      "PLOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcelle"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
    },
    "SamplingGuidance": {
      "HORIZONTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
      "VERTICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
    },
    "SamplingRowType": {
      "ROW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
      "INTER_ROW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inter-rang"])}
    },
    "LandCoverType": {
      "URBAN_FABRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zones urbanisées"])},
      "INDUSTRIAL_COMMERCIAL_AND_TRANSPORT_UNITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zones industrielles ou commerciales et réseaux de communication"])},
      "MINE_DUMP_AND_CONSTRUCTION_SITES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mines, décharges et chantiers"])},
      "ARTIFICIAL_NON_AGRICULTURAL_VEGETATED_AREAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces verts artificialisés, non agricoles"])},
      "ARABLE_LAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terres arables"])},
      "PERMANENT_CROPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cultures permanentes"])},
      "PASTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prairies"])},
      "HETEROGENEOUS_AGRICULTURAL_AREAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zones agricoles hétérogènes"])},
      "FORESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forêts"])},
      "SCRUB_AND_OR_HERBACEOUS_VEGETATION_ASSOCIATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milieux à végétation arbustive ou herbacée"])},
      "OPEN_SPACE_WITH_LITTLE_OR_NO_VEGETATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces ouverts, sans ou avec peu de végétation"])},
      "INLAND_WETLANDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zones humides intérieures"])},
      "MARITIME_WETLANDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zones humides maritimes"])},
      "INLAND_WATERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eaux continentales"])},
      "MARINE_WATERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eaux maritimes"])}
    },
    "Practice": {
      "PLOUGHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour"])},
      "SUPERFICIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travail superficiel"])},
      "NONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans travail du sol"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
    }
  }
}
export default resource